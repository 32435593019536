import TextInput from '../../components/TextInput'
import React, { Fragment, useCallback, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { CancelButton, PrimaryButton } from '../../components/Buttons'
import { useTranslation } from 'react-i18next'
import { errorTextMap, OrderDetailsFormValidator, validateVin } from '../../util/validators'
import {
    journeyStatusValuesEnum,
    DEALER_ROLE,
    TRANSLATIONS_NAMESPACE,
    orderCancelledKey,
} from '../../constants/global'
import { FormWrapperPadding } from '../../components/FormElements'
import { useUpdateCustomerActions } from '../../hooks/actions/useUpdateCustomerActions'
import { INPUT_SHORT } from '../../constants/defaultFormValues'
import TextInfo from '../../components/TextInfo'
import RequiredBox from '../../components/RequiredBox'
import {
    Box,
    FormControl,
    FormHelperText,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core'
import { MediumWidthCard } from '../../components/Wrappers/MediumWithCard'
import { CardTitle } from '../../components/Wrappers/Card'
import ServicePartnerSearchInput from '../../components/ServicePartnerSearchInput/ServicePartnerSearchInput'
import CancelOrderPopUp from '../../components/PopUp/variants/CancelOrderPopUp'
import { Vehicles } from '../../api'
import { getDateFormat } from '../../util/util'
import { DateTime } from 'luxon'

const isFormValid = (validationErrors) => {
    const formProperties = ['brand', 'commissionNumber', 'vin', 'orderNumber', 'psp']
    return formProperties.every((item) => validationErrors[item] === null)
}

export default function UpdateOrderDetails({
    orderDetails,
    updateOrderDetailsStateData,
    requestedServices,
    customerId,
    statuses,
    getStatusesData,
    customerEnrolled,
}) {
    const { userInfo } = useSelector((state) => state.oidc)
    const { selectedLanguage } = useSelector((state) => state.options)
    const [editMode, setEditMode] = useState(false)
    const { updateOrderDetails } = useUpdateCustomerActions()
    const [data, setData] = useState({ ...orderDetails })
    const [errors, setErrors] = useState({})
    const [isCancelOrderPopupOpen, setIsCancelOrderPopupOpen] = useState(false)
    const checkExistingVehicleHandleRef = useRef(null)

    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const vehicleStatuses = statuses.vehicleJourneyStatuses[0]
    const formId = 'order-details'

    const dateFormat = getDateFormat(selectedLanguage)

    const year = new Date().getFullYear()

    const handleEditMode = () => {
        if (!editMode && !data.dealer?.id) {
            setData((prevState) => ({
                ...prevState,
                dealer: {
                    id: userInfo.username,
                    firstName: userInfo.firstName,
                    lastName: userInfo.lastName,
                },
            }))
        }
        setEditMode(!editMode)
    }

    const handleChange = (e) => {
        e.persist()
        setData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const handleChangeCN = (e) => {
        e.persist()
        setData((prevState) => ({
            ...prevState,
            commissionNumber: e.target.value.toUpperCase(),
        }))
    }

    const setPsp = (id, name) => {
        setData((prevState) => ({
            ...prevState,
            preferredServicePartnerId: id,
            preferredServicePartnerName: name,
        }))
        setErrors((prevState) => ({
            ...prevState,
            psp: null,
        }))
    }

    const setModelName = (modelName) => {
        setData((prevState) => ({
            ...prevState,
            modelName,
        }))
    }

    const onCancelEditing = () => {
        handleEditMode()
        setData({ ...orderDetails })
    }

    const validate = useCallback(() => {
        const validationErrors = OrderDetailsFormValidator(data, requestedServices)
        setErrors(validationErrors)
        return validationErrors
    }, [data, requestedServices])

    const onChangeVinHandle = (vin) => {
        setModelName('')
        setErrors((prevState) => ({
            ...prevState,
            vin: null,
        }))
        checkExistingVehicleHandleRef.current &&
            window.clearTimeout(checkExistingVehicleHandleRef.current)
        if (vin && validateVin(vin) === null) {
            checkExistingVehicleHandleRef.current = window.setTimeout(async () => {
                try {
                    const modelNameResponse = await Vehicles.getModelNameByVin(vin)
                    modelNameResponse.data?.modelName &&
                        setModelName(modelNameResponse.data.modelName)
                } catch (e) {
                    console.error(e)
                }
            }, 500)
        }
    }

    const onChangeCommissionNumberFullHandle = async (commissionNumberFull) => {
        setErrors((prevState) => ({
            ...prevState,
            commissionNumber: null,
        }))
        if (/\d{6}-\d{3}-\d{4}/.test(commissionNumberFull)) {
            checkExistingVehicleHandleRef.current = window.setTimeout(async () => {
                try {
                    const commissionNumberParts = commissionNumberFull.split('-')

                    const vinAndModelNameResponse =
                        await Vehicles.getVinAndModelNameByCommissionNumber(
                            commissionNumberParts[0],
                            commissionNumberParts[1],
                            commissionNumberParts[2]
                        )
                    if (vinAndModelNameResponse.data?.vin) {
                        setData((prevState) => ({
                            ...prevState,
                            vin: vinAndModelNameResponse.data.vin,
                        }))
                    }
                    vinAndModelNameResponse.data?.modelName &&
                        setModelName(vinAndModelNameResponse.data.modelName)
                } catch (e) {
                    console.error(e)
                }
            }, 500)
        }
    }

    const onSaveClick = async () => {
        const validationErrors = validate()
        setErrors(validationErrors)
        if (isFormValid(validationErrors)) {
            const response = await updateOrderDetails(vehicleStatuses.vehicleId, {
                ...data,
                commissionNumberBid: userInfo.bid,
            })
            updateOrderDetailsStateData(data)
            if (response?.body?.errorKey) {
                const errorPair = response.body.errorKey.split(':')
                setErrors({ ...errors, [errorPair[0]]: errorPair[1] })
            } else {
                handleEditMode()
                getStatusesData(customerId)
            }
        }
    }

    return (
        <MediumWidthCard>
            <CardTitle>{t('general.order.title')}</CardTitle>
            <FormWrapperPadding>
                <form noValidate autoComplete="off">
                    <Box mb={2}>
                        <Grid container spacing={3}>
                            <Grid item sm={4}>
                                <TextInput
                                    formId={formId}
                                    handleChange={handleChange}
                                    inputId={'salesPerson'}
                                    label={t('general.order.salesPerson')}
                                    value={
                                        data.dealer &&
                                        data.dealer?.firstName &&
                                        data.dealer?.lastName
                                            ? `${data.dealer.firstName} ${data.dealer.lastName}`
                                            : ''
                                    }
                                    fullWidth
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item sm={4}>
                                <TextInput
                                    formId={formId}
                                    inputId={'brand'}
                                    label={t('general.order.brand.title')}
                                    value={data.brand || ''}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <Grid item sm={4}>
                                <TextInput
                                    formId={formId}
                                    inputId={'vehicleType'}
                                    label={t('general.order.vehicleType')}
                                    value={data.vehicleType || ''}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mb={2}>
                        <Grid container spacing={3}>
                            <Grid item sm={4}>
                                <TextInput
                                    formId={formId}
                                    handleChange={(e) => {
                                        handleChange(e)
                                        onChangeVinHandle(e.target.value)
                                    }}
                                    inputId={'vin'}
                                    label={t('general.order.vin')}
                                    value={data.vin || ''}
                                    fullWidth
                                    error={errors.vin}
                                    disabled={!editMode || data.vehicleValidated}
                                    tooltipText={t('general.order.vin.tooltip')}
                                    maxLength={INPUT_SHORT}
                                    warningLabelText={
                                        orderDetails.vin &&
                                        orderDetails.connectCapabilityStatus === false &&
                                        orderDetails.dealer &&
                                        orderDetails.dealer.firstName
                                            ? t('general.order.capability.status')
                                            : undefined
                                    }
                                    // MSP-69037
                                    // helperText={!errors.vin && data.modelName}
                                />
                            </Grid>
                            <Grid item sm={4}>
                                <Grid container spacing={1} wrap={'nowrap'}>
                                    <Grid item sm={6}>
                                        <FormControl error={!!errors.commissionNumber}>
                                            <InputLabel style={{ whiteSpace: 'nowrap' }}>
                                                {t('general.order.commission')}
                                            </InputLabel>

                                            <Input
                                                name={'commissionNumber'}
                                                value={data.commissionNumber ?? ''}
                                                fullWidth
                                                inputProps={{
                                                    maxLength: 6,
                                                }}
                                                style={{
                                                    letterSpacing: '1px',
                                                }}
                                                error={!!errors.commissionNumber}
                                                onChange={(e) => {
                                                    handleChangeCN(e)
                                                    onChangeCommissionNumberFullHandle(
                                                        `${e.target.value}-${userInfo.bid}-${data.commissionNumberYear}`
                                                    )
                                                }}
                                                placeholder={'______'}
                                                disabled={!editMode || data.vehicleValidated}
                                                maxLength={6}
                                            />
                                        </FormControl>
                                        {errors.commissionNumber && (
                                            <FormHelperText style={{ whiteSpace: 'nowrap' }} error>
                                                {errorTextMap[errors.commissionNumber] ? (
                                                    <>
                                                        {t('general.order.commission')}{' '}
                                                        {t(errorTextMap[errors.commissionNumber])}
                                                    </>
                                                ) : (
                                                    t(errors.commissionNumber) || null
                                                )}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item sm={3}>
                                        <FormControl error={!!errors.commissionNumber}>
                                            <InputLabel></InputLabel>
                                            <Input
                                                name={'bid'}
                                                value={userInfo.bid ?? ''}
                                                disabled
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <FormControl error={!!errors.commissionNumber} fullWidth>
                                            <InputLabel></InputLabel>
                                            <Select
                                                name={'commissionNumberYear'}
                                                value={data.commissionNumberYear || ''}
                                                fullWidth
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    onChangeCommissionNumberFullHandle(
                                                        `${data.commissionNumber}-${userInfo.bid}-${e.target.value}`
                                                    )
                                                }}
                                                disabled={!editMode || data.vehicleValidated}
                                            >
                                                <MenuItem value={year + 1}>{year + 1}</MenuItem>
                                                <MenuItem value={year}>{year}</MenuItem>
                                                <MenuItem value={year - 1}>{year - 1}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={4}>
                                <TextInput
                                    formId={formId}
                                    handleChange={handleChange}
                                    inputId={'orderNumber'}
                                    label={t('general.order.orderNumber')}
                                    value={data.orderNumber || ''}
                                    fullWidth
                                    error={errors.orderNumber}
                                    disabled={!editMode || data.vehicleValidated}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box mb={4}>
                        <Grid container spacing={3} alignItems={'flex-end'}>
                            <Grid item xs={4}>
                                <TextInput
                                    formId={formId}
                                    inputId={'dateOfCreation'}
                                    label={t('general.customers.table.col.created')}
                                    value={
                                        data.dateOfCreation
                                            ? DateTime.fromJSDate(data.dateOfCreation).toFormat(
                                                  dateFormat
                                              )
                                            : ''
                                    }
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextInput
                                    formId={formId}
                                    inputId={'orderNumber'}
                                    label={t('general.customers.table.col.deliveryDate')}
                                    value={
                                        data.deliveryDateToImporter
                                            ? DateTime.fromJSDate(
                                                  data.deliveryDateToImporter
                                              ).toFormat(dateFormat)
                                            : ''
                                    }
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mb={4}>
                        <Grid container spacing={3} alignItems={'flex-end'}>
                            <Grid item xs={4}>
                                <ServicePartnerSearchInput
                                    orderDetails={data}
                                    setPsp={setPsp}
                                    errors={errors}
                                    editMode={editMode}
                                    disabled={!editMode || customerEnrolled}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    {editMode &&
                        vehicleStatuses.preferredServicePartnerSet ===
                            journeyStatusValuesEnum.COMPLETED && (
                            <Grid container spacing={3}>
                                <TextInfo message={t('general.order.serviceScheduling.complete')} />
                            </Grid>
                        )}
                    {editMode && <RequiredBox withBottom />}
                    {userInfo &&
                        userInfo.role === DEALER_ROLE &&
                        !editMode &&
                        vehicleStatuses[orderCancelledKey]?.state !==
                            journeyStatusValuesEnum.FAILED && (
                            <Fragment>
                                <Box display={'flex'} justifyContent={'center'}>
                                    <PrimaryButton
                                        label={t('general.button.order.update')}
                                        clickAction={handleEditMode}
                                    />
                                </Box>
                                <Box display={'flex'} justifyContent={'center'}>
                                    <CancelButton
                                        label={t('general.button.order.cancel')}
                                        clickAction={() => setIsCancelOrderPopupOpen(true)}
                                    />
                                </Box>
                            </Fragment>
                        )}
                    {userInfo &&
                        userInfo.role === DEALER_ROLE &&
                        !editMode &&
                        vehicleStatuses[orderCancelledKey]?.state ===
                            journeyStatusValuesEnum.FAILED && (
                            <Fragment>
                                <Box display={'flex'} justifyContent={'center'}>
                                    <PrimaryButton
                                        label={t('general.button.order.new')}
                                        clickAction={handleEditMode}
                                    />
                                </Box>
                            </Fragment>
                        )}
                    {editMode && (
                        <Fragment>
                            <Box display={'flex'} justifyContent={'center'}>
                                <PrimaryButton
                                    label={t('general.save')}
                                    clickAction={onSaveClick}
                                />
                            </Box>
                            <Box display={'flex'} justifyContent={'center'}>
                                <CancelButton
                                    label={t('general.button.cancel')}
                                    clickAction={onCancelEditing}
                                />
                            </Box>
                        </Fragment>
                    )}
                </form>
            </FormWrapperPadding>
            <CancelOrderPopUp
                vehicleId={vehicleStatuses.vehicleId}
                customerId={customerId}
                getStatusesData={getStatusesData}
                isOpen={isCancelOrderPopupOpen}
                setIsOpen={setIsCancelOrderPopupOpen}
            />
        </MediumWidthCard>
    )
}
